@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    img {
      @apply inline-block;
    }
  }

  /* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaaaaa;
  border-radius: 5px;
  height: 20px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #9f9f9f;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9; /* Optional: reset to default border color or any color of choice */
  box-shadow: none; /* Remove shadow if any */
  outline: none; /* Remove outline */
}