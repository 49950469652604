/* Typography */
.h1 {
    @apply text-4xl font-extrabold tracking-tighter;
}

.h2 {
    @apply text-3xl font-extrabold tracking-tighter;
}

.h3 {
    @apply text-3xl font-extrabold;
}

.h4 {
    @apply text-2xl font-extrabold tracking-tight;
}

@screen md {
    .h1 {
        @apply text-5xl;
    }

    .h2 {
        @apply text-4xl;
    }
}

/* Buttons */
.btn,
.btn-lg,
.btn-sm,
.btn-xs {
    @apply font-medium text-sm  inline-flex items-center justify-center border border-transparent rounded leading-5 shadow-sm transition duration-150 ease-in-out;
}

.btn {
    @apply px-3 py-2;
}

.btn-lg {
    @apply px-4 py-3;
}

.btn-sm {
    @apply px-2 py-1;
}

.btn-xs {
    @apply px-2 py-0.5;
}

/* Forms */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {
    @apply bg-white dark:bg-slate-900/30 border focus:ring-0 focus:ring-offset-0 dark:disabled:bg-slate-700/30 dark:disabled:border-slate-700 dark:disabled:hover:border-slate-700;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox {
    @apply rounded;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select {
    @apply text-sm text-slate-800 dark:text-slate-100 leading-5 py-2 px-3 border-slate-200 hover:border-slate-300 focus:border-slate-300 dark:border-slate-700 dark:hover:border-slate-600 dark:focus:border-slate-600 shadow-sm;
}

.form-input,
.form-textarea {
    @apply placeholder-slate-400 dark:placeholder-slate-500;
}

.form-select {
    @apply pr-10;
}

.form-checkbox,
.form-radio {
    @apply text-royalblue-500 checked:bg-royalblue-500 dark:checked:border-transparent border border-slate-300 focus:border-royalblue-300 dark:border-slate-700 dark:focus:border-royalblue-500/50;
}

/* Switch element */
.form-switch {
    @apply relative select-none;
    width: 44px;
}

.form-switch label {
    @apply block overflow-hidden cursor-pointer h-6 rounded-full;
}

.form-switch label > span:first-child {
    @apply absolute block rounded-full;
    width: 20px;
    height: 20px;
    top: 2px;
    left: 2px;
    right: 50%;
    transition: all .15s ease-out;
}

.form-switch input[type="checkbox"]:checked + label {
    @apply bg-royalblue-500;
}

.form-switch input[type="checkbox"]:checked + label > span:first-child {
    left: 22px;
}

.form-switch input[type="checkbox"]:disabled + label {
    @apply cursor-not-allowed bg-slate-100 dark:bg-slate-700/20 border border-slate-200 dark:border-slate-700;
}

.form-switch input[type="checkbox"]:disabled + label > span:first-child {
    @apply bg-slate-400 dark:bg-slate-600;
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}