.App {
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.ant-input-affix-wrapper{
  border-radius: 12px !important;


}

.ant-input-affix-wrapper .ant-input{
  border-radius: 0px !important;
  border: 0px !important;
}
.ant-input{
  border-radius: 12px !important;
  border : 1px solid #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.88) !important;
}

.ant-select-selector{
  border-radius: 12px !important;
  border : 1px solid #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.88) !important;
  overflow:hidden !important;
}


.form-input:focus,
.form-textarea:focus,
.form-multiselect:focus,
.form-select:focus,
.form-checkbox:focus,
.form-radio:focus {
  @apply ring-0;
}

.unread-row {
  background-color: #f5f5f5; /* Light grey background for unread messages */
}
